<template>
  <div id="main">
    <div class="login d-flex align-items-center">
      <div class="w-100">
        <h1 class="title text-center mb-4">
          <div>The Nikon Difference</div>
        </h1>
        <div class="wrap">
          <div v-if="!modalPrivacyShow">
            <div class="px-5 py-4 p-md-6">
              <form>
                <div class="form-group mt-3">
                  <label for="email">Email Address</label>
                  <input
                    v-model="user.username"
                    type="text"
                    class="form-control"
                    required
                    @keyup.enter="handleLogin"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Password</label>
                  <input
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control"
                    @keyup.enter="handleLogin"
                  />
                  <div class="form-check mt-1">
                    <input
                      id="shkShowPassword"
                      type="checkbox"
                      class="form-check-input"
                      @change="tooglePassword"
                    />
                    <label class="form-check-label" for="shkShowPassword">
                      Show password
                    </label>
                  </div>
                </div>
                <div class="form-group btn-login">
                  <button
                    type="button"
                    class="btn btn-primary rounded submit px-3"
                    @click="handleLogin"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div v-else hide-footer>
            <div class="px-5 py-5 p-md-6">
              <div class="form-group mt-3">
                <p><b>Privacy Policy Notice</b></p>
              </div>
              <div class="form-group">
                <p style="margin-bottom: 0px">
                  Please review the privacy policy.
                </p>
                <p style="margin-bottom: 0px">
                  By accepting, you agree to the
                </p>
                <p style="margin-bottom: 0px">
                  term in our Privacy Policy.
                </p>
              </div>
              <div>
                <a
                  :href="privacyStatementUrl"
                  target="_blank"
                  style="color: black; text-decoration: underline"
                >
                  Read the full statement here.
                </a>
              </div>
              <div style="padding-top: 50px">
                <button
                  class="decline-privacy xs-mt1 btn btn-secondary"
                  style="width: 100px"
                  @click="hideModal"
                >
                  Decline
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded submit px-3 agree-privacy"
                  block
                  @click="confirmPrivacy"
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!modalPrivacyShow"
          class="text-right forgot-password-container"
        >
          <router-link
            :to="{ path: '/forgot-password' }"
            class="forgot-password"
          >
            Forgot password?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentService from "@/service/DocumentService";
import Vue from 'vue';
import User from "../../models/user";
import AccessService from "../../service/AccessService";
import CountryPrivacyService from "../../service/CountryPrivacyService";
import CountryService from "../../service/CountryService";
import LanguageService from "../../service/LanguageService";
import LocalStoreService from "../../service/LocalStoreService";

export default {
  name: "Login",
  data() {
    return {
      user: new User(""),
      message: "",
      showPassword: false,
      modalPrivacyShow: false,
      loggedUser: null,
      privacyStatementUrl: "#",
      withOtp: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    disabledLogin() {
      return !(this.user.username && this.user.password);
    },
  },
  created() {
    const withOtp = this.$route.query.otp;
    if (withOtp) {
      this.withOtp = true;
    }
    Vue.$cookies.remove('token');
  },
  methods: {
    tooglePassword() {
      this.showPassword = !this.showPassword;
    },
    hideModal() {
      this.modalPrivacyShow = false;
    },
    confirmPrivacy() {
      const user = this.loggedUser;
      // this.hideModal();
      if (user.token) {
        const hostname = window.location.hostname;
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://raw.githubusercontent.com/wrangr/psl/master/dist/psl.min.js");

        let psl = require('psl')
        let parsed = psl.parse(hostname);

        LocalStoreService.setUser(user);
        Vue.$cookies.set('token', user.token, '300s', null, parsed.domain);
      }
      this.doRedirect();
    },
    onChangedPassword() {
      this.doRedirect();
    },
    doRedirect() {
      const user = this.loggedUser;
      CountryService.getAll().then((response) => {
        this.$store.commit(
          "common/countries",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });
      LanguageService.getAll().then((response) => {
        this.$store.commit(
          "common/languages",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });
      AccessService.getAll().then((response) => {
        this.$store.commit(
          "common/accesses",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });
      this.$store.commit("survey/remove");
      if (user.isGlobal) {
        this.$router.push("/");
      } else if(!user.isAccessToLensSelector){
        window.location.href = DocumentService.getRedirectUrl(user);
      } else {
        this.$router.push("/survey");
      }
    },
    handleLogin() {
      const user = { ...this.user, otp: this.withOtp };
      this.$store.dispatch("auth/login", user).then(
        (user) => {
          if (!user) {
            return;
          }
          if(user && user.expiration) document.cookie = `expiration=${user.expiration}`;
          CountryPrivacyService.getPrivacyStatementUrl(user).then(
            (response) => {
              if (response?.data?.privacyUrl) {
                this.privacyStatementUrl = response.data.privacyUrl;
              }
              this.loggedUser = user;
              // Show privacy popup
              this.modalPrivacyShow = true;
            }
          );
        },
        (error) => {
          console.log(error);
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped>
#main {
  background-color: #ffffff;
  color: black;
}
.login {
  margin: auto;
  width: 445px;
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.title {
  width: 100%;
}
.wrap {
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}
.main-form {
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}
.form-control {
  height: calc(2.25rem + 2px);
}
.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.forgot-password {
  color: black;
}
.forgot-password-container {
  margin-top: 5px;
}
.btn-login {
  text-align: right;
}
.btn[type="submit"] {
  height: 100%;
}
.agree-privacy {
  float: right;
  width: 100px;
}
.agree-privacy:hover {
  background-color: #093c8f !important;
  transition: background-color 0.15s ease 0s !important;
}
.decline-privacy {
  background-color: #6c757d;
  color: white;
  border-color: #6c757d;
}
.decline-privacy:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
.decline-privacy,
.agree-privacy,
.btn-login {
  height: 38px !important;
}
</style>
