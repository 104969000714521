import axios from "axios";
import { CONTEXT_PATH } from '../constants/Paths';

const BASE_URL = '/country-privacy'

const http = axios.create({
  baseURL: CONTEXT_PATH
});

class CountryPrivacyService {
  getPrivacyStatementUrl(user) {
    return http.get(`${BASE_URL}/get`, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    });
  }

  updatePrivacyStatementUrl(user, privacyUrl) {
    return http.put(`${BASE_URL}/update`, {
      privacyUrl
    }, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    });
  }
}

export default new CountryPrivacyService();